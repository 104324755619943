@tailwind base;
@layer base {
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        /* For some Androids */
    }

    b {
        @apply font-semibold;
    }

    html,
    button,
    input,
    textarea,
    select {
        outline: none;
        @apply antialiased;
    }

    button:focus + input:focus + textarea:focus + select:focus {
        outline: none;
    }

    button:focus-visible + input:focus-visible + textarea:focus-visible {
        outline: auto 1px;
    }

    input {
        @apply h-10;
    }

    /* Carried over from base.import.less. Hopefully we can delete/simplify these rules in due time... */
    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
        vertical-align: unset;
        @apply inline;
    }

    ol {
        padding-inline-start: 40px;
        @apply list-decimal;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-normal m-0 p-0;
    }

    h1 {
        @apply text-3xl;
    }

    h2 {
        @apply text-2xl;
    }

    h3 {
        @apply text-xl;
    }

    h4 {
        @apply text-lg;
    }

    em {
        @apply font-semibold not-italic;
    }

    /* Tailwind's css rule for [hidden] has very low specificity and is overridden
   By any classes that assign to `display` */
    [hidden] {
        @apply !hidden;
    }

    .vector-effect-non-scaling-stroke * {
        vector-effect: non-scaling-stroke;
    }
}

@tailwind components;
@tailwind utilities;

/* This is a class that will be injected by recurly, we need this for our
    credit card forms. */
.recurly-hosted-field {
    @apply m-0 border-0 w-full h-[34px];
}

.grecaptcha-badge {
    visibility: hidden;
}

/* Aeonik
@font-face {
    font-family: 'Aeonik';
    src: url('../fonts/aeonik/Aeonik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../fonts/aeonik/Aeonik-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../fonts/aeonik/Aeonik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../fonts/aeonik/Aeonik-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../fonts/aeonik/Aeonik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('../fonts/aeonik/Aeonik-RegularItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Fira Code 
@font-face {
    font-family: 'Fira Code';
    src: url('../fonts/firaCode/FiraCode-VariableFont_wght.ttf') format('truetype');
}

/* Inter 
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
} */

@layer utilities {
    .hide-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .hide-scrollbar::-webkit-scrollbar {
        @apply hidden;
    }

    .active-tab-decorator::before {
        content: ' ';
        @apply w-full bg-blilet h-1 absolute block rounded-t -bottom-[1px] left-0;
    }

    .bg-stripes-red {
        background: repeating-linear-gradient(
            135deg,
            white,
            white 10px,
            rgb(255, 253, 253) 10px,
            rgb(255, 253, 253) 20px
        );
    }

    .focus-ring {
        @apply ring-1 ring-offset-2 ring-offset-blue;
    }

    .text-heading-3xl {
        @apply text-3xl font-medium font-heading text-heading;
    }

    .text-heading-2xl {
        @apply text-2xl font-medium font-heading text-heading;
    }

    .text-heading-xl {
        @apply text-xl font-medium font-heading text-heading;
    }

    .text-heading-lg {
        @apply text-lg font-medium font-heading text-heading;
    }

    .text-title {
        @apply text-base font-medium font-heading text-heading;
    }

    .text-subtitle {
        @apply text-base font-body text-secondary;
    }

    .text-body {
        @apply text-base font-body text-primary;
    }

    .text-body-sb {
        @apply text-body font-semibold;
    }

    .text-body-sm {
        @apply text-sm font-body text-primary;
    }

    .text-body-sm-sb {
        @apply text-body-sm font-semibold;
    }

    .text-label {
        @apply text-xs font-bold uppercase text-secondary;
    }

    .text-code {
        font-weight: 450;
        @apply text-base font-code text-code-primary;
    }

    .text-code-sb {
        font-weight: 450;
        @apply text-code font-semibold;
    }

    .text-code-sm {
        font-weight: 450;
        @apply text-sm text-code text-code-primary;
    }

    .text-code-sm-sb {
        @apply text-sm text-code font-semibold text-code-primary;
    }
}
